/**
 *  Animation main navigation Levle 1- > level 2
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import {
    style, animate, transition, group, trigger, query, stagger,
} from '@angular/animations';

export const ENTERPARTICIPANT = trigger('enterParticipant', [
    transition(':enter', [
        query('.participant-compare-data__row', [
            style({ transform: 'translateY(-100%)', height: '*' }),

            stagger(200, [
                group([
                    animate('300ms ease-in', style({ transform: 'translateY(0%)' })),
                    animate('500ms ease-in', style({ height: '100%' })),
                ]),
            ]),

        ]),

    ]),

]);
