/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/**
 *  Homepage component
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2020 livescore
 */

import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Subscription, merge } from 'rxjs';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { BaseComponent } from '@pages/base.component';
import { PageService } from '@services/page.service';
import { InfoService } from '@services/info.service';
import { ClientService } from '@services/client.service';
import { LangService } from '@services/lang.service';
import * as __ from 'underscore';
import { UtilsService } from '@services/utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ENTERPARTICIPANT } from '@shared/animations/participant-compare.animation';
import { ParticipantService } from '@services/participant.service';
import { delay, switchMap, tap } from 'rxjs/operators';
import SPORT_CONFIG from '@config/sport.config';

import { isNumber, has } from 'lodash-es';

import { H2HlDisplayMode, MatchData, MatchState, Team } from '@interfaces/match-data.interface';

import { Store } from '@ngxs/store';


import { MatDialog } from '@angular/material/dialog';

import { Form, ParticipantCompareInterface, ParticipantInterface } from '@interfaces/participant.interface';

import { PARTICIPANTSTATS } from '@shared/animations/participant-compare-stats.animation';
import { CupTreeComponent } from '@components/cup-tree/cup-tree.component';

import { GetOdds, UpdateMarkets } from '@/store/actions/bets.action';


declare const moment: any;


@Component({
    selector: 'livescore-participant-compare',
    templateUrl: './participant-compare.component.html',
    animations: [ENTERPARTICIPANT, PARTICIPANTSTATS],

    styles: [],
})
export class ParticipantCompareComponent extends PageMixin(BaseComponent) implements OnInit {
    private subscriber: Subscription;

    public sportId: number;

    public sportName: string;

    public parentClass: Record<string, boolean> = { 'col-lg-12': true, 'col-lg-8': false };

    public matchClass: Record<string, boolean> = {
        'col-lg-4': true,
        'd-none': true,
        'd-lg-block': false,
    };

    public matchId: number | null = null;

    public tracker: boolean = false;
    public stream: boolean = false;

    public formVal: Record<string, string | number> = { first: '-', second: '-' };

    public matchState: MatchState;

    public data: ParticipantCompareInterface;

    public mode: H2HlDisplayMode;

    public odds: number[] | null = null;

    public brId: number | null;

    public match?: MatchData;
    public participants: { [prop: string]: Team };

    public participantHome: ParticipantInterface | any;
    public participantAway: ParticipantInterface | any;

    public playerParticipant: number;
    public playerParticipant2: number;
    public playerParticipant3: number;
    public playerParticipant4: number;


    public participantTableSeason: Set<[string, string, number, 'home' | 'away', number?, number?]>;

    public activeStatsSub: number | null = null;

    private section = 'all';

    public activeBackButton: boolean = false;

    public tableData?: MatchData;

    public constructor(
        protected page: PageService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        public client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        private router: ActivatedRoute,
        private participant: ParticipantService,
        private deviceService: DeviceDetectorService,
        private store: Store,
        public dialog: MatDialog,

    ) {
        super(page, title, meta, route, info);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();
        this.store.dispatch(new UpdateMarkets());
        this.router.paramMap.subscribe((params) => {
            [this.sportId, this.sportName] = UtilsService.parseSportUrl(params.get('sport')!);
        });

        this.reset();

        this.router.data
            .pipe(
                switchMap((data) => {
                    const [first, second] = data.participants;
                    return this.participant.getCompare(first, second);
                }),
            )
            .subscribe((data) => {
                this.data = data;

                this.playerParticipant = this.data.first?.base?.id;
                this.playerParticipant2 = this.data.first?.base?.id;
                this.playerParticipant3 = this.data.first?.base?.id;
                this.playerParticipant4 = this.data.first?.base?.id;

                if (this.data.data?.finished && this.data.data?.finished.length > 0) {
                    this.data.data.finished = this.data.data.finished.reverse();

                    const { id } = this.data.data.finished[0]!;
                    this.loadMatch(id);

                    // eslint-disable-next-line prefer-destructuring
                    this.tableData = this.data.data.finished[0];
                }

                if (this.data.data?.not_started && this.data.data?.not_started.length > 0) {
                    const { id } = this.data.data.not_started[0]!;
                    this.loadMatch(id);
                    // eslint-disable-next-line prefer-destructuring
                    this.tableData = this.data.data.not_started[(this.data.data.not_started.length - 1)];
                }

                if (this.data.data?.not_started && this.data.data?.not_started.length > 0) {
                    const matchId = this.data.data.not_started[0]!.id;
                    this.store.dispatch(new GetOdds([matchId], true)).subscribe((v) => {
                        if (v.bets?.odds?.[matchId]?.[0].odd_types) {
                            this.odds = v.bets.odds[matchId][0].odd_types.map((v2: Record<string, any>) => v2.rate)
                                .slice(0, 3);
                            this.brId = this.data.data.not_started![0]!.tracker!;
                        }
                    });
                }
                this.lang.getLang().then((): Promise<any> => this.createTitle());

                this.finished(true);
            }, () => {
                this.error(true);
            });


        this.router.queryParamMap.subscribe((param: ParamMap) => {
            this.mode = param.get('mode') as H2HlDisplayMode;

            if (this.mode === 'widget') {
                UtilsService.addClass(document.getElementsByTagName('body')[0], 'h2h-widget--mode');
            } else {
                this.activeBackButton = true;
            }
        });
    }

    public onMatchLoad(match: MatchData): void {
        this.match = match;
        this.participants = __.indexBy(match.participants, 'type') as any;

        if (!this.participantHome && !this.participantAway) {
            this.loadParticipants();
        }
    }

    public setStandingsStats(participantId: number, participant: ParticipantInterface): void {
        this.calculateTableStandings(participantId, participant);
        this.loadStandingsStats(participantId);
    }

    private calculateTableStandings(participantId: number, participant: ParticipantInterface): void {
        this.participantTableSeason = new Set();

        const uniqIds: string[] = [];
        participant.data!.finished!.forEach((element: MatchData) => {
            if (element.tracker && element.hasTable && element.participants) {
                const key = `${element.category_name!}-${element.sub_tournament_name}`;
                const type = element.participants[0]!.id === participantId ? 'home' : 'away';
                if (!uniqIds.includes(key)) {
                    this.participantTableSeason.add([element.category_name,
                        element.sub_tournament_name, element.tracker, type, element.season_id,
                        element.sub_tournament_id]);
                }
                uniqIds.push(key);
            }
        });

        if (this.participantTableSeason.size > 0) {
            const myArr = Array.from(this.participantTableSeason)[0];
            [,, this.activeStatsSub] = myArr!;
        }
    }

    public openCuptree():void {
        const dialogRef = this.dialog.open(CupTreeComponent, {
            maxWidth: '94%',
            width: '94%',
            maxHeight: '94%',
            height: '94%',
            panelClass: 'app-cup-tree',
            data: {
                subId: this.tableData?.sub_tournament_id,
                seasonId: this.tableData?.season_id,
            },
        });
        dialogRef.afterClosed().subscribe((): void => {
            // console.log('The dialog was closed');
        });
    }

    public loadStandingsStats(participantId: number): void {
        const request: any[] = [];
        const seasons:[string, string, number, 'home' | 'away', number?, number?][] = [];

        Array.from(this.participantTableSeason)
            .forEach((season:[string, string, number, 'home' | 'away',
                (number | undefined)?, (number | undefined)?]) => {
                if (season[5]) {
                    request.push(this.participant.getParticipantStandings(season[4], season[5],
                        participantId, this.lang.getLangSnapshot()));
                    seasons.push(season);
                }
            });

        forkJoin(request).subscribe((resp: any[]) => {
            if (Array.isArray(resp)) {
                resp.forEach((req: any[], key) => {
                    if (req.length === 0) {
                        this.participantTableSeason
                            .delete(seasons[key] as [string, string, number, 'home' | 'away', number?, number?]);
                    }
                });
            }
        });
    }

    get calculateTableStandingsPath(): string | null {
        let find = null;

        if (this.participantTableSeason) {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of Array.from(this.participantTableSeason)) {
                if (item[2] === this.activeStatsSub) {
                    find = item;
                    break;
                }
            }
        }

        if (!find) {
            return null;
        }
        return `/statistics/participant-season-standings-graph/${find[2]}/${find[3]}`;
    }

    public activeSection(section: string): void {
        this.section = section;
    }

    public isSection(section:string): boolean {
        return this.section === 'all' || this.section === section;
    }

    public dateFormat(match: MatchData): string {
        const Time = moment.utc(match.start_date).local();
        const Date = this.isMobile ? 'localDateTimeShort' : 'localDateShort';
        return Time.isSame(moment(), 'day') ? 'time' : Date;
    }

    public dateFormat2(match: MatchData): string {
        const Time = moment.utc(match.start_date).local();
        const Date = this.isMobile ? 'localDateShort' : 'localDateShort';
        return Time.isSame(moment(), 'day') ? 'time' : Date;
    }

    get formFiltered1(): Form[] {
        return this.data.first.base.form.reverse().filter((f: Record<string, any>) => f.code_state !== 'not_started');
    }

    get formFiltered2(): Form[] {
        return this.data.second.base.form.reverse().filter((f: Record<string, any>) => f.code_state !== 'not_started');
    }

    get hasTable(): boolean {
        return !!(this.tableData?.hasTable);
    }

    get hasCupTree(): boolean {
        return !!(this.tableData?.hasCupTree);
    }

    public setForm(type: 'first' | 'second', form: number): void {
        this.formVal[type] = form;
    }

    /**
     * Event fired when some match is selected/clicked
     * @event ParticipantComponent#loadMatch
     * @param  match
     * @return {void}
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public loadMatch(match: any): void {
        if (isNumber(match)) {
            this.matchId = match;
            this.toggleDetail();
            return;
        }

        if (this.matchId != null && this.matchId === match.id) {
            return;
        }
        this.toggleDetail();
        this.matchState = match.match_state;
        this.matchId = match.id;
        this.tracker = false;
        if (has(match, 'tracker')) {
            this.tracker = match.tracker as boolean;
        }
        if (has(match, 'stream')) {
            this.stream = match.stream as boolean;
        }
    }

    private toggleDetail(active: boolean = true): void {
        this.parentClass['col-lg-8'] = active;
        this.parentClass['col-lg-12'] = !active;
        this.matchClass['d-lg-block'] = active;
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    get winFirst(): number {
        const { id } = this.data.first.base;
        let firstWin = 0;

        if (this.data.data.finished) {
            this.data.data.finished.forEach((data) => {
                const isHome = id === data.participants[0]!.id;

                if ((isHome && data.winner === 1) || (!isHome && data.winner === 2)) {
                    firstWin += 1;
                }
            });
        }
        return firstWin;
    }

    get winSecond(): number {
        const { id } = this.data.second.base;
        let secondWin = 0;

        if (this.data.data.finished) {
            this.data.data.finished.forEach((data) => {
                const isHome = id === data.participants[0]!.id;

                if ((isHome && data.winner === 1) || (!isHome && data.winner === 2)) {
                    secondWin += 1;
                }
            });
        }
        return secondWin;
    }

    get hasStats(): boolean {
        return this.hasTotalStats || this.hasGoalsStats || this.hasUOStats ||
            this.hasScoredGoalsStats || this.hasTimelineStats;
    }

    get hasTotalStats(): boolean {
        return SPORT_CONFIG.statistics.totals.includes(this.sportName);
    }

    get hasTimelineStats(): boolean {
        return SPORT_CONFIG.statistics.timeline.includes(this.sportName);
    }

    get hasGoalsStats(): boolean {
        return SPORT_CONFIG.statistics.goals.includes(this.sportName);
    }

    get hasScoredGoalsStats(): boolean {
        return SPORT_CONFIG.statistics.scoredGoals.includes(this.sportName);
    }

    get hasUOStats(): boolean {
        return SPORT_CONFIG.statistics.uo.includes(this.sportName);
    }

    get hasMatchScoreStats(): boolean {
        return SPORT_CONFIG.statistics.matchScore.includes(this.sportName);
    }

    get hasTableStandings(): boolean {
        return !!(this.participantTableSeason && this.participantTableSeason.size > 0 &&
        SPORT_CONFIG.statistics.participantSeasonStandings.includes(this.sportName));
    }

    get hasTennisBilance(): boolean {
        return this.isTennis &&
                                this.participantHome &&
                                this.participantHome.balance.length > 0 &&
                                this.participantAway.balance.length > 0;
    }

    get hasPlayers(): boolean {
        return this.participantHome &&
                                this.participantAway &&
                                this.participantHome.players.length > 0 &&
                                this.participantAway.players.length > 0;
    }

    public get getNearSeason(): number | null {
        return this.data?.data?.finished && this.data.data.finished.length > 0
            ? this.data.data.finished[0]!.season_id!
            : null;
    }

    public get isTennis(): boolean {
        return SPORT_CONFIG.isTennis(this.sportName);
    }

    get hasFinished(): boolean {
        return !!(this.data.data?.finished && this.data.data?.finished?.length > 0);
    }

    get hasNotStarted(): boolean {
        return !!(this.data.data?.not_started && this.data.data?.not_started?.length > 0);
    }

    get heightFirst(): string {
        const height = this.data.first?.base?.meta?.basic?.find(val => val.name === 'height');
        const { value = null } = height ?? {};
        return value ?? this.data.first?.base?.height ?? '-';
    }

    get heightSecond(): string {
        const height = this.data.second?.base?.meta?.basic?.find(val => val.name === 'height');
        const { value = null } = height ?? {};
        return value ?? this.data.second?.base?.height ?? '-';
    }

    get weightFirst(): string {
        const weight = this.data.first?.base?.meta?.basic?.find(val => val.name === 'weight');
        const { value = null } = weight ?? {};
        return value ?? this.data.first?.base?.weight ?? '-';
    }

    get weightSecond(): string {
        const weight = this.data.second?.base?.meta?.basic?.find(val => val.name === 'weight');
        const { value = null } = weight ?? {};
        return value ?? this.data.second?.base?.weight ?? '-';
    }

    get dateBirthFirst(): string {
        const birth = this.data.first?.base?.meta?.main?.find(val => val.name === 'birthdate');
        const { value = null } = birth ?? {};
        return value ?? '-';
    }

    get dateBirthSecond(): string {
        const birth = this.data.second?.base?.meta?.main?.find(val => val.name === 'birthdate');
        const { value = null } = birth ?? {};
        return value ?? '-';
    }

    get nicknameFirst(): string {
        const nickname = this.data.first?.base?.meta?.main?.find(val => val.name === 'nickname');
        const { value = null } = nickname ?? {};
        return value ?? '-';
    }

    get nicknameSecond(): string {
        const nickname = this.data.second?.base?.meta?.main?.find(val => val.name === 'nickname');
        const { value = null } = nickname ?? {};
        return value ?? '-';
    }

    get handFirst(): string {
        const hand = this.data.first?.base?.meta?.main?.find(val => val.name === 'dominant-hand');
        const { value = null } = hand ?? {};
        return value ?? '-';
    }

    get handSecond(): string {
        const hand = this.data.second?.base?.meta?.main?.find(val => val.name === 'dominant-hand');
        const { value = null } = hand ?? {};
        return value ?? '-';
    }

    get weightClassFirst(): string {
        const weightClass = this.data.first?.base?.meta?.main?.find(val => val.name === 'weight-class');
        const { value = null } = weightClass ?? {};
        return value ?? '-';
    }

    get weightClassSecond(): string {
        const weightClass = this.data.second?.base?.meta?.main?.find(val => val.name === 'weight-class');
        const { value = null } = weightClass ?? {};
        return value ?? '-';
    }

    get gymFirst(): string {
        const gym = this.data.first?.base?.meta?.basic?.find(val => val.name === 'gym');
        const { value = null } = gym ?? {};
        return value ?? '-';
    }

    get gymSecond(): string {
        const gym = this.data.second?.base?.meta?.basic?.find(val => val.name === 'gym');
        const { value = null } = gym ?? {};
        return value ?? '-';
    }


    get koFirst(): string {
        const record = this.data.first?.base?.meta?.statistics?.find(val => val.name === 'ko-tko');
        const { value = null } = record ?? {};
        return value ?? '-';
    }

    get koSecond(): string {
        const record = this.data.second?.base?.meta?.statistics?.find(val => val.name === 'ko-tko');
        const { value = null } = record ?? {};
        return value ?? '-';
    }

    get decisionsFirst(): string {
        const record = this.data.first?.base?.meta?.statistics?.find(val => val.name === 'decisions');
        const { value = null } = record ?? {};
        return value ?? '-';
    }

    get decisionsSecond(): string {
        const record = this.data.second?.base?.meta?.statistics?.find(val => val.name === 'decisions');
        const { value = null } = record ?? {};
        return value ?? '-';
    }

    get proRecordFirst(): string {
        const proRecord = this.data.first?.base?.meta?.statistics?.find(val => val.name === 'pro-record-w-l-d');
        const { value = null } = proRecord ?? {};
        return value ?? '-';
    }

    get proRecordSecond(): string {
        const proRecord = this.data.second?.base?.meta?.statistics?.find(val => val.name === 'pro-record-w-l-d');
        const { value = null } = proRecord ?? {};
        return value ?? '-';
    }

    get currentStreakFirst(): string {
        const currentStreak = this.data.first?.base?.meta?.statistics?.find(val => val.name === 'current-streak');
        const { value = null } = currentStreak ?? {};
        return value ?? '-';
    }

    get currentStreakSecond(): string {
        const currentStreak = this.data.second?.base?.meta?.statistics?.find(val => val.name === 'current-streak');
        const { value = null } = currentStreak ?? {};
        return value ?? '-';
    }

    get homeStadiumFirst(): string | number {
        // eslint-disable-next-line camelcase
        return this.data.first?.base?.home_stadium ?? '-';
    }

    get homeStadiumSecond(): string | number {
        // eslint-disable-next-line camelcase
        return this.data.second?.base?.home_stadium ?? '-';
    }

    get singleRainkingFirst(): string | number {
        // eslint-disable-next-line camelcase
        return this.data.first?.base?.tennis_rankings_single ?? '-';
    }

    get singleRainkingSecond(): string | number {
        // eslint-disable-next-line camelcase
        return this.data.second?.base?.tennis_rankings_single ?? '-';
    }

    get doubleRainkingFirst(): string | number {
        // eslint-disable-next-line camelcase
        return this.data.first?.base?.tennis_rankings_double ?? '-';
    }

    get metaStatistics(): Record<string, any>[] {
        let map: [Record<string, any>, Record<string, any>][] = [];

        if (this.data.first?.base?.meta?.statistics) {
            map = this.data.first?.base?.meta?.statistics.map(
                (v): [Record<string, any>, Record<string, any>] | undefined => {
                    const second = this.data.second?.base?.meta?.statistics?.find(v2 => v.name === v2.name);

                    if (second) {
                        return [v, second];
                    }

                    return undefined;
                }).filter(v => !!(v)) as [Record<string, any>, Record<string, any>][];
        }
        // eslint-disable-next-line camelcase
        return map;
    }

    get doubleRainkingSecond(): string | number {
        // eslint-disable-next-line camelcase
        return this.data.second?.base?.tennis_rankings_double ?? '-';
    }

    get isoFirst(): string {
        return this.data.first?.base?.country?.isoCode.toLowerCase() ?? '-';
    }

    get isoSecond(): string {
        return this.data.second?.base?.country?.isoCode.toLowerCase() ?? '-';
    }

    get countryFirst(): string {
        return this.data.first?.base?.country?.name ?? '-';
    }

    get countrySecond(): string {
        return this.data.second?.base?.country?.name ?? '-';
    }

    get matches(): any[] {
        return [];
    }

    get isEmpty(): boolean {
        return this.matches.length === 0;
    }

    private async loadParticipants(): Promise<void> {
        const home = await this.participant.get(this.participants!.home_team!.id, 40);
        const away = await this.participant.get(this.participants!.away_team!.id, 40);

        merge((home as Observable<any>).pipe(tap((p: ParticipantInterface) => {
            this.participantHome = p;
        })),

        (away as Observable<any>).pipe(tap((p: ParticipantInterface) => {
            this.participantAway = p;
        }))).pipe(delay(200)).subscribe(() => {
            if (typeof this.participantTableSeason === 'undefined') {
                this.setStandingsStats(this.data.first?.base?.id, this.participantHome);
            }
        });
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_match').subscribe((): void => {
            const names = `${this.data.first?.base?.name} vs ${this.data.second?.base?.name}`;
            const trans = this.translate.instant('web.title_match');
            const sport = this.translate.instant(`sport.${this.sportName}`);
            const desc = this.translate.instant('web.description_match');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${sport} | ${names} ${trans} | ${client}`,
                    description: `${names} - LIVE - ${sport} ${desc}`,
                },
                true,
            );
        });
    }


    public trackByFn(index: number, item: Record<any, any>): number {
        return item.id;
    }
}
