<app-header
    *ngIf="mode !== 'widget'"
    [fullMenu]="false"
    [sportMenu]="true"
    [sportId]="sportId"
    [activeBackButton]="activeBackButton"
></app-header>
<app-light-header *ngIf="mode === 'widget'"></app-light-header>
<div class="container bg-light position-relative">
    <div class="row bg-white">
        <div class="col-lg-8 bg-white p-0" [ngClass]="parentClass">
            <div
                *ngIf="loaded; else elseBlock"
                class="tab-content bg-white px-3 pb-3 pt-sm-3 has-nomenclature position-relative"
            >
                <ng-container *ngIf="loadedError; else loadBlock">
                    <div class="" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-7">
                            <svg class="icon icon--64 mb-4"><use xlink:href="#icon-player" /></svg>
                            <h4 class="font-weight-bold">{{ 'web.no_competitor_found' | translate }}</h4>
                            <p class="mb-6">{{ 'web.error_competitor_load' | translate }}</p>
                        </div>
                    </div>
                </ng-container>
                <ng-template #loadBlock>
                    <div class="participant-compare-page d-flex justify-content-center">
                        <div
                            data-cy="participant1"
                            class="participant-compare-subject d-flex flex-column justify-content-start align-items-center"
                        >
                            <div class="participant-compare-subject__name text-center">
                                <a
                                    [routerLink]="[
                                        '/page/sport/participant',
                                        sportName + '-' + sportId,
                                        data.first?.base?.id
                                    ]"
                                    class="participant-compare-subject__name-link"
                                    >{{ data.first?.base?.name }}</a
                                >
                            </div>
                            <div class="participant-compare-subject__logo">
                                <img
                                    height="100"
                                    class="participant-compare-subject__logo-image"
                                    [src]="data.first?.base?.image"
                                    alt="Cressy, Maxime"
                                />
                            </div>
                            <div class="mt-2 d-none d-md-block">
                                <app-last-matches [limit]="6" [matches]="data.first.base.form"></app-last-matches>
                            </div>
                        </div>
                        <div
                            [@enterParticipant]
                            class="participant-compare-data d-flex flex-column justify-content-start align-items-center"
                        >
                            <div class="participant-compare-data__score text-center">
                                <span data-cy="winFirst">{{ winFirst }} </span>-
                                <span data-cy="winSecond">{{ winSecond }}</span>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="countryFirst !== '-' || countrySecond !== '-'"
                            >
                                <div
                                    class="participant-compare-data__row-data text-right app-word-nowrap d-flex justify-content-between"
                                >
                                    <ng-container *ngIf="isoFirst !== '-'">
                                        <img
                                            width="20"
                                            height="20"
                                            loading="lazy"
                                            class="mr-1 rounded-circle ng-star-inserted"
                                            src="/img/flags/1x1/{{ isoFirst }}.svg?t=1621587714"
                                            [alt]="countryFirst"
                                        />
                                    </ng-container>
                                    <div class="participant-compare-data__row-data--overflow">
                                        {{ countryFirst }}
                                    </div>
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.country_s' | translate }}
                                </div>
                                <div
                                    class="participant-compare-data__row-data participant-compare-data__row-data--overflow text-left app-word-nowrap d-flex justify-content-between"
                                >
                                    <div class="participant-compare-data__row-data--overflow">
                                        {{ countrySecond }}
                                    </div>
                                    <ng-container *ngIf="isoSecond !== '-'">
                                        <img
                                            width="20"
                                            height="20"
                                            loading="lazy"
                                            class="ml-1 rounded-circle ng-star-inserted"
                                            src="/img/flags/1x1/{{ isoSecond }}.svg?t=1621587714"
                                            [alt]="countrySecond"
                                        />
                                    </ng-container>
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="singleRainkingFirst !== '-' || singleRainkingSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ singleRainkingFirst }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.tennis_single_rainking_s' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ singleRainkingSecond }}
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="doubleRainkingFirst !== '-' || doubleRainkingSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ doubleRainkingFirst }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.tennis_double_rainking_s' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ doubleRainkingSecond }}
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngFor="let stats of metaStatistics; trackBy: trackByIndex"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ stats[0].value }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ stats[0].translation | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ stats[1].value }}
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="nicknameFirst !== '-' || nicknameSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ nicknameFirst }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.nickname_s' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ nicknameSecond }}
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="weightClassFirst !== '-' || weightClassSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ weightClassFirst }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.weight_class_s' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ weightClassSecond }}
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="proRecordFirst !== '-' || proRecordSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ proRecordFirst }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.pro_rcord_s' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ proRecordSecond }}
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="koFirst !== '-' || koSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ koFirst }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.ko-tko' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ koSecond }}
                                </div>
                            </div>
                            <div
                                *ngIf="decisionsFirst !== '-' || decisionsSecond !== '-'"
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ decisionsFirst }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.mma-decisions' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ decisionsSecond }}
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="currentStreakFirst !== '-' || currentStreakSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ currentStreakFirst }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.current_streak_s' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ currentStreakSecond }}
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="gymFirst !== '-' || gymSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ gymFirst }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.gym_s' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ gymSecond }}
                                </div>
                            </div>

                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="dateBirthFirst !== '-' || dateBirthSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    {{ dateBirthFirst | livDate : 'birthdate' }}
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.birthdate_s' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    {{ dateBirthSecond | livDate : 'birthdate' }}
                                </div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--odd d-flex"
                                *ngIf="heightFirst !== '-' || heightSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">{{ heightFirst }} cm</div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.height' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">{{ heightSecond }} cm</div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--even d-flex"
                                *ngIf="weightFirst !== '-' || weightSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">{{ weightFirst }} kg</div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.weight' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">{{ weightSecond }} kg</div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--even d-flex"
                                *ngIf="handFirst !== '-' || handSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">
                                    <ng-container *ngIf="handForst !== '-'; else hand1">
                                        {{ handFirst | translate }}</ng-container
                                    >
                                    <ng-template #hand1>
                                        {{ handFirst }}
                                    </ng-template>
                                </div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.dominant_hand_s' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">
                                    <ng-container *ngIf="handSecond !== '-'; else hand2">
                                        {{ handSecond | translate }}</ng-container
                                    >
                                    <ng-template #hand2>
                                        {{ handSecond }}
                                    </ng-template>
                                </div>
                            </div>
                            <div class="participant-compare-data__row participant-compare-data__row--even d-flex">
                                <div class="participant-compare-data__row-data text-right">{{ formVal.first }}%</div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.participant_form_p' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">{{ formVal.second }}%</div>
                            </div>
                            <div
                                class="participant-compare-data__row participant-compare-data__row--even d-flex"
                                *ngIf="homeStadiumFirst !== '-' || homeStadiumSecond !== '-'"
                            >
                                <div class="participant-compare-data__row-data text-right">{{ homeStadiumFirst }}</div>
                                <div class="participant-compare-data__row-head text-center">
                                    {{ 'web.home_stadium' | translate }}
                                </div>
                                <div class="participant-compare-data__row-data text-left">{{ homeStadiumSecond }}</div>
                            </div>
                        </div>
                        <div
                            data-cy="participant2"
                            class="participant-compare-subject d-flex flex-column justify-content-start align-items-center"
                        >
                            <div class="participant-compare-subject__name text-center">
                                <a
                                    [routerLink]="[
                                        '/page/sport/participant',
                                        sportName + '-' + sportId,
                                        data.second?.base?.id
                                    ]"
                                    class="participant-compare-subject__name-link"
                                    >{{ data.second?.base?.name }}</a
                                >
                            </div>
                            <div class="participant-compare-subject__logo">
                                <img
                                    height="100"
                                    class="participant-compare-subject__logo-image"
                                    [src]="data.second?.base?.image"
                                    alt="Cressy, Maxime"
                                />
                            </div>
                            <div class="mt-2 d-none d-md-block">
                                <app-last-matches [limit]="6" [matches]="data.second.base.form"></app-last-matches>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane active mt-5">
                        <div class="mb-2 d-flex flex-wrap justify-content-start">
                            <a (click)="activeSection('all')" rel="noopener" class="app-compare-link">{{
                                'web.widget_menu_all' | translate
                            }}</a>
                            <a
                                (click)="activeSection('collapse-not_started')"
                                class="app-compare-link"
                                rel="noopener"
                                *ngIf="hasNotStarted"
                                >{{ 'web.not_started_matches' | translate | transinit }}</a
                            >
                            <a
                                (click)="activeSection('collapse-finished')"
                                class="app-compare-link"
                                rel="noopener"
                                *ngIf="hasFinished"
                                >{{ 'web.latest_matches' | translate | transinit }}</a
                            >
                            <a
                                (click)="activeSection('collapse-h2h')"
                                class="app-compare-link"
                                rel="noopener"
                                *ngIf="match && match?.h2h?.total"
                                >{{ 'web.match_h2h' | translate | transinit }}</a
                            >
                            <a
                                (click)="activeSection('collapse-players')"
                                class="app-compare-link"
                                rel="noopener"
                                *ngIf="hasPlayers"
                                >{{ 'web.players' | translate | transinit }}</a
                            >
                            <a
                                (click)="activeSection('collapse-statistics')"
                                class="app-compare-link"
                                rel="noopener"
                                *ngIf="hasStats"
                                >{{ 'web.participant_stats_global' | translate }}</a
                            >
                            <a
                                (click)="activeSection('collapse-league_table')"
                                class="app-compare-link"
                                rel="noopener"
                                *ngIf="hasTable"
                                >{{ 'web.league_table' | translate | transinit }}</a
                            >
                            <a (click)="openCuptree()" class="app-compare-link" rel="noopener" *ngIf="hasCupTree">{{
                                'web.cup_tree' | translate | transinit
                            }}</a>
                            <a
                                (click)="activeSection('collapse-standings')"
                                class="app-compare-link"
                                rel="noopener"
                                *ngIf="hasTableStandings"
                                >{{ 'web.participant_stats_season_standings' | translate }}</a
                            >

                            <a
                                (click)="activeSection('collapse-tennis-bilance')"
                                rel="noopener"
                                class="app-compare-link"
                                *ngIf="hasTennisBilance"
                            >
                                {{ 'web.tennis_bilance' | translate | transinit }}</a
                            >
                        </div>
                        <!-- Probability widget -->
                        <div *ngIf="odds && odds.length > 2" class="table-sport mx-n3 mx-sm-0">
                            <h6
                                class="table-sport__head bg-light-emph-compare"
                                data-toggle="collapse"
                                data-target="#collapse-probability"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-probability"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.widget_win_probability_title' | translate | transinit
                                }}</span>
                            </h6>
                            <div class="table-sport__content collapse show" id="collapse-probability">
                                <iframe
                                    style="width: 100%; height: 240px"
                                    frameborder="0"
                                    scrolling="no"
                                    [src]="
                                        '/widgets/w/3way;notitle=1;odds=' +
                                            odds.join(',') +
                                            ';id=' +
                                            brId +
                                            '?lang=' +
                                            lang.getLangSnapshot() | safe
                                    "
                                ></iframe>
                            </div>
                        </div>

                        <!-- Upcomming matches -->
                        <div
                            class="table-sport mx-n3 mx-sm-0"
                            *ngIf="hasNotStarted && isSection('collapse-not_started')"
                            [@stats]
                        >
                            <h6
                                class="table-sport__head bg-light-emph-compare"
                                data-toggle="collapse"
                                data-target="#collapse-not_started"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-not_started"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.not_started_matches' | translate | transinit
                                }}</span>
                            </h6>
                            <div class="table-sport__content collapse show" id="collapse-not_started">
                                <app-component-match
                                    *ngFor="let match of data.data.not_started; trackBy: trackById"
                                    [dateFormat]="dateFormat(match)"
                                    [dateFormat2]="dateFormat2(match)"
                                    [attr.data-cyid]="match.id"
                                    [match]="match"
                                    [showFavorite]="true"
                                    [displayOdds]="isActiveOddsTab"
                                    [sportName]="match.sport_code_name"
                                    [sportId]="match.sport_id"
                                    (onSelect)="loadMatch($event)"
                                >
                                </app-component-match>
                            </div>
                        </div>
                        <!-- Finished matches -->
                        <div
                            class="table-sport mx-n3 mx-sm-0"
                            *ngIf="hasFinished && isSection('collapse-finished')"
                            [@stats]
                        >
                            <h6
                                class="table-sport__head bg-light-emph-compare"
                                data-toggle="collapse"
                                data-target="#collapse-finished"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-finished"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.latest_matches' | translate | transinit
                                }}</span>
                            </h6>
                            <div
                                class="table-sport__content collapse show"
                                id="collapse-finished"
                                fragment="collapse-finished"
                            >
                                <app-component-match
                                    *ngFor="let match of data.data.finished; trackBy: trackById"
                                    [dateFormat]="dateFormat(match)"
                                    [dateFormat2]="dateFormat2(match)"
                                    [attr.data-cyid]="match.id"
                                    [match]="match"
                                    [showFavorite]="true"
                                    [displayOdds]="isActiveOddsTab"
                                    [sportName]="match.sport_code_name"
                                    [sportId]="match.sport_id"
                                    (onSelect)="loadMatch($event)"
                                >
                                </app-component-match>
                            </div>
                        </div>
                        <!-- H2H -->
                        <div
                            class="table-sport mx-n3 mx-sm-0"
                            *ngIf="match && match?.h2h?.total && isSection('collapse-h2h')"
                            [@stats]
                        >
                            <h6
                                class="table-sport__head bg-light-emph-compare"
                                data-toggle="collapse"
                                data-target="#collapse-h2h"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-h2h"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.match_h2h' | translate | transinit
                                }}</span>
                            </h6>
                            <div class="table-sport__content collapse show" id="collapse-h2h">
                                <app-h2h
                                    [home]="participants.home_team?.name"
                                    [away]="participants.away_team?.name"
                                    [homeShort]="participants.home_team?.name_short ?? participants.home_team?.name"
                                    [awayShort]="participants.away_team?.name_short ?? participants.away_team?.name"
                                    [home_logo]="participants.home_team?.image"
                                    [away_logo]="participants.away_team?.image"
                                    [data]="match?.h2h"
                                    [tracker]="match.metainfo.tracker"
                                    [sportName]="match?.sport_code_name"
                                    [sportId]="match?.sport_id"
                                ></app-h2h>
                            </div>
                        </div>

                        <!-- league Table -->
                        <div
                            class="table-sport mx-n3 mx-sm-0"
                            *ngIf="hasTable && isSection('collapse-league_table')"
                            [@stats]
                        >
                            <h6
                                class="table-sport__head bg-light-emph-compare"
                                data-toggle="collapse"
                                data-target="#collapse-league_table"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-league_table"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.league_table' | translate | transinit
                                }}</span>
                            </h6>
                            <div class="table-sport__content collapse show" id="collapse-league_table">
                                <app-league-table
                                    [sportName]="tableData?.sport_code_name"
                                    [sportId]="tableData?.sport_id"
                                    [onlyOne]="true"
                                    [participantHome]="tableData.participants[0].id"
                                    [participantAway]="tableData.participants[1].id"
                                    [subTournamentId]="tableData?.sub_tournament_id"
                                    [seasonId]="tableData?.season_id"
                                    [isLive]="false"
                                ></app-league-table>
                            </div>
                        </div>
                        <!-- Players -->
                        <div
                            [@stats]
                            class="table-sport mx-n3 mx-sm-0"
                            *ngIf="hasPlayers && isSection('collapse-players')"
                        >
                            <h6
                                class="table-sport__head bg-light-emph-compare"
                                data-toggle="collapse"
                                data-target="#collapse-players"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-players"
                            >
                                <span class="table-sport__head__text">{{ 'web.players' | translate | transinit }}</span>
                            </h6>
                            <div class="app-small-nav my-3 mr-1 text-right">
                                <a
                                    class="crumb__link active app-cursor mr-1"
                                    (click)="playerParticipant = data.first!.base!.id"
                                    [class.active]="playerParticipant === data.first?.base?.id"
                                    >{{ data.first?.base?.name }}
                                </a>
                                <a
                                    class="crumb__link app-cursor"
                                    (click)="playerParticipant = data.second!.base!.id"
                                    [class.active]="playerParticipant === data.second?.base?.id"
                                    >{{ data.second?.base?.name }}</a
                                >
                            </div>

                            <div class="table-sport__content collapse show" id="collapse-players">
                                <app-players-v2
                                    *ngIf="playerParticipant === data.first?.base?.id"
                                    [data]="participantHome.players"
                                    [sportName]="sportName"
                                    [sportId]="sportId"
                                    [seasonId]="getNearSeason"
                                ></app-players-v2>
                                <app-players-v2
                                    *ngIf="playerParticipant === data.second?.base?.id"
                                    [data]="participantAway.players"
                                    [sportName]="sportName"
                                    [sportId]="sportId"
                                    [seasonId]="getNearSeason"
                                ></app-players-v2>
                            </div>
                        </div>
                        <!-- Statistics -->
                        <div
                            class="table-sport mx-n3 mx-sm-0"
                            *ngIf="hasStats && isSection('collapse-statistics')"
                            [@stats]
                        >
                            <h6
                                class="table-sport__head bg-light-emph-compare"
                                data-toggle="collapse"
                                data-target="#collapse-statistics"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-statistics"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.participant_stats_global' | translate
                                }}</span>
                            </h6>
                            <div class="app-small-nav my-3 mr-1 text-right">
                                <a
                                    class="crumb__link active app-cursor mr-1"
                                    (click)="playerParticipant3 = data.first!.base!.id"
                                    [class.active]="playerParticipant3 === data.first?.base?.id"
                                    >{{ data.first?.base?.name }}
                                </a>
                                <a
                                    class="crumb__link app-cursor"
                                    (click)="playerParticipant3 = data.second!.base!.id"
                                    [class.active]="playerParticipant3 === data.second?.base?.id"
                                    >{{ data.second?.base?.name }}</a
                                >
                            </div>

                            <div class="table-sport__content collapse show" id="collapse-statistics">
                                <app-participant-stats
                                    *ngIf="playerParticipant3 === data.first?.base?.id"
                                    [pid]="data.first?.base?.id!"
                                    [sportName]="sportName"
                                >
                                    <app-participant-stats-totals
                                        *ngIf="hasTotalStats"
                                        #playerStats
                                    ></app-participant-stats-totals>
                                    <app-participant-stats-goals
                                        *ngIf="hasGoalsStats"
                                        #playerStats
                                    ></app-participant-stats-goals>
                                    <app-participant-stats-uo
                                        *ngIf="hasUOStats"
                                        #playerStats
                                    ></app-participant-stats-uo>
                                    <app-participant-stats-match-result
                                        *ngIf="hasMatchScoreStats"
                                        #playerStats
                                    ></app-participant-stats-match-result>
                                    <app-participant-stats-scored-goals
                                        *ngIf="hasScoredGoalsStats"
                                        #playerStats
                                    ></app-participant-stats-scored-goals>
                                    <app-participant-stats-timeline
                                        *ngIf="hasTimelineStats"
                                        #playerStats
                                    ></app-participant-stats-timeline>
                                </app-participant-stats>

                                <app-participant-stats
                                    *ngIf="playerParticipant3 === data.second?.base?.id"
                                    [pid]="data.second?.base?.id!"
                                    [sportName]="sportName"
                                >
                                    <app-participant-stats-totals
                                        *ngIf="hasTotalStats"
                                        #playerStats
                                    ></app-participant-stats-totals>
                                    <app-participant-stats-goals
                                        *ngIf="hasGoalsStats"
                                        #playerStats
                                    ></app-participant-stats-goals>
                                    <app-participant-stats-uo
                                        *ngIf="hasUOStats"
                                        #playerStats
                                    ></app-participant-stats-uo>
                                    <app-participant-stats-match-result
                                        *ngIf="hasMatchScoreStats"
                                        #playerStats
                                    ></app-participant-stats-match-result>
                                    <app-participant-stats-scored-goals
                                        *ngIf="hasScoredGoalsStats"
                                        #playerStats
                                    ></app-participant-stats-scored-goals>
                                    <app-participant-stats-timeline
                                        *ngIf="hasTimelineStats"
                                        #playerStats
                                    ></app-participant-stats-timeline>
                                </app-participant-stats>
                            </div>
                        </div>
                        <!-- Standings -->
                        <div
                            class="table-sport mx-n3 mx-sm-0 mt-2"
                            *ngIf="hasTableStandings && isSection('collapse-standings')"
                            [@stats]
                        >
                            <h6
                                class="table-sport__head bg-light-emph-compare"
                                data-toggle="collapse"
                                data-target="#collapse-standings"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-standings"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.participant_stats_season_standings' | translate
                                }}</span>
                            </h6>
                            <div class="app-small-nav my-3 mr-1 text-right">
                                <a
                                    class="crumb__link active app-cursor mr-1"
                                    (click)="
                                        setStandingsStats(data.first?.base?.id!, participantHome);
                                        playerParticipant2 = data.first!.base!.id
                                    "
                                    [class.active]="playerParticipant2 === data.first?.base?.id"
                                    >{{ data.first?.base?.name }}
                                </a>
                                <a
                                    class="crumb__link app-cursor"
                                    (click)="
                                        setStandingsStats(data.second?.base?.id!, participantAway);
                                        playerParticipant2 = data.second!.base!.id
                                    "
                                    [class.active]="playerParticipant2 === data.second?.base?.id"
                                    >{{ data.second?.base?.name }}</a
                                >
                            </div>

                            <div class="table-sport__content collapse show" id="collapse-standings">
                                <ng-container
                                    *ngIf="
                                        playerParticipant2 === data.first?.base?.id ||
                                        playerParticipant2 === data.second?.base?.id
                                    "
                                >
                                    <nav class="p-1">
                                        <ol class="app-small-nav my-3 mr-1">
                                            <li
                                                class="crumb ng-star-inserted"
                                                *ngFor="let item of this.participantTableSeason; trackBy: trackByIndex"
                                            >
                                                <a
                                                    class="crumb__link active app-cursor"
                                                    (click)="activeStatsSub = item[2]"
                                                    [class.active]="item[2] === activeStatsSub"
                                                    >{{ item[1] }} ({{ item[0] }})</a
                                                >
                                            </li>
                                        </ol>
                                    </nav>
                                    <div class="d-flex table-scrollable">
                                        <div class="table-scrollable__data flex-grow-1" tableScroll>
                                            <iframe
                                                *ngIf="calculateTableStandingsPath"
                                                class="participant-standings-iframe"
                                                frameborder="0"
                                                scrolling="no"
                                                [src]="
                                                    calculateTableStandingsPath + '?lang=' + lang.getLangSnapshot()
                                                        | safe
                                                "
                                            ></iframe>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <!-- Tennis Bilance -->
                        <div
                            class="table-sport mx-n3 mx-sm-0"
                            [@stats]
                            *ngIf="hasTennisBilance && isSection('collapse-tennis-bilance')"
                        >
                            <h6
                                class="table-sport__head bg-light-emph-compare"
                                data-toggle="collapse"
                                data-target="#collapse-tennis-bilance"
                                role="button"
                                aria-expanded="true"
                                aria-controls="collapse-tennis-bilance"
                            >
                                <span class="table-sport__head__text">{{
                                    'web.tennis_bilance' | translate | transinit
                                }}</span>
                            </h6>
                            <div class="app-small-nav my-3 mr-1 text-right">
                                <a
                                    class="crumb__link active app-cursor mr-1"
                                    (click)="playerParticipant4 = data.first!.base!.id"
                                    [class.active]="playerParticipant4 === data.first?.base?.id"
                                    >{{ data.first?.base?.name }}
                                </a>
                                <a
                                    class="crumb__link app-cursor"
                                    (click)="playerParticipant4 = data.second!.base!.id"
                                    [class.active]="playerParticipant4 === data.second?.base?.id"
                                    >{{ data.second?.base?.name }}</a
                                >
                            </div>

                            <div class="table-sport__content collapse show" id="collapse-tennis-bilance">
                                <app-tennis-bilance
                                    *ngIf="playerParticipant4 === data.first?.base?.id"
                                    [bilance]="participantHome.balance"
                                ></app-tennis-bilance>
                                <app-tennis-bilance
                                    *ngIf="playerParticipant4 === data.second?.base?.id"
                                    [bilance]="participantAway.balance"
                                ></app-tennis-bilance>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <div class="d-none">
                    <app-participant-form
                        [form]="formFiltered1 | slice : 0 : 10"
                        (onLoad)="setForm('first', $event)"
                    ></app-participant-form>
                    <app-participant-form
                        [form]="formFiltered2 | slice : 0 : 10"
                        (onLoad)="setForm('second', $event)"
                    ></app-participant-form>
                </div>
            </div>
            <ng-template #elseBlock>
                <div class="my-5">
                    <app-page-loader></app-page-loader>
                </div>
            </ng-template>
        </div>
        <div class="col-lg-4 d-none d-lg-block" [ngClass]="matchClass">
            <app-switchers (onStickyChange)="onStickyChange($event)"></app-switchers>
            <div [class.sticky-parent]="stickyDetail" class="col-right__inner bg-white" data-cy="right-column-match">
                <app-match-detail
                    class="sticky-parent__match"
                    [style.max-height]="viewPortHeightMatchDetail"
                    [id]="matchId"
                    [tracker]="tracker"
                    [matchState]="matchState"
                    [stream]="stream"
                    (onLoad)="onMatchLoad($event)"
                ></app-match-detail>
                <div>
                    <div>
                        <app-participant-comparition
                            [sportId]="sportId"
                            [sportName]="sportName"
                        ></app-participant-comparition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>s
