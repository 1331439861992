import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ComponentModule } from '@components/component.module';


import { ParticipantCompareRoutingModule } from './participant-compare-routing.module';

import { ParticipantCompareComponent } from './participant-compare.component';

import { SharedModule } from '@/shared/shared.module';


@NgModule({
    declarations: [ParticipantCompareComponent],
    imports: [CommonModule, SharedModule, ParticipantCompareRoutingModule, ComponentModule],
})
export class ParticipantCompareModule { }
