/**
 *  Animation main navigation Levle 1- > level 2
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2024 livescore
 */
import {
    style, animate, transition, trigger,
} from '@angular/animations';

export const PARTICIPANTSTATS = trigger('stats', [
    // transition(':enter', [

    //     style({ transform: 'translateX(-100%)', height: '*' }),
    //     animate('400ms ease-in', style({ transform: 'translateX(0%)' })),


    // ]),
    transition(':leave', [

        style({ transform: 'translateX(0%)', height: '*' }),
        animate('400ms ease-in', style({ transform: 'translateX(-200%)' })),


    ]),

]);
